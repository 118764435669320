import React from "react"
import { Page } from "../components/layout"
import { UniversalLink } from "../components/UniversalLink"
import judgesData from "../data/judgesData.json"
import "./judges.scss"

const affiliates = [
  {
    image: `anchain.png`,
    title: `Anchain,ai`,
  },
  {
    image: `anu.png`,
    title: `Australian National University`,
  },
  {
    image: `delft.png`,
    title: `Delft Blockchain Lab`,
  },
  {
    image: `bowie-state-university.png`,
    title: `Bowie State University`,
  },
  {
    image: `rmi.png`,
    title: `RMI`,
  },
  {
    image: `university-of-oregon.png`,
    title: `University of Oregon`,
  },
  {
    image: `ceres.png`,
    title: `Ceres`,
  },
  {
    image: `morgan-state-university.png`,
    title: `Morgan State University`,
  },
  {
    image: `ripple.png`,
    title: `Ripple`,
  },
  {
    image: `stronghold.png`,
    title: `Stronghold`,
  },
  {
    image: `forte.png`,
    title: `Forte`,
  },
  {
    image: `ucl.png`,
    title: `University College London`,
  },
  {
    image: `university-of-michigan.png`,
    title: `University of Michigan`,
  },
  {
    image: `upenn.png`,
    title: `University of Pennsylvania`,
  },
  {
    image: `fg.png`,
    title: `FG`,
  },
  {
    image: `falconx.png`,
    title: `FalconX`,
  },
  {
    image: `collective-works.png`,
    title: `Collective Works`,
  },
  {
    image: `shima-capital.png`,
    title: `Shima Capital`,
  },
  {
    image: `shokworks.png`,
    title: `Shokworks`,
  },
  {
    image: `blockchain-founders-group.png`,
    title: `Blockchain Founders Group`,
  },
  {
    image: `nanyang-technological-university.png`,
    title: `Nanyang Technological University`,
  },
  {
    image: `mercy-corps-ventures.png`,
    title: `Mercy Corps Ventures`,
  },
  {
    image: `lift.png`,
    title: `Lab for Inclusive FinTech at UC Berkeley `,
  },
]

export const Judge: React.FC<{
  judge: { name: string; title: string; image: string }
}> = ({ judge }) => (
  <div className="judge col-span-1 sm:col-span-2 md:col-span-4 lg:col-span-3">
    <img
      src={`/images/judges/${judge.image}`}
      alt={judge.name}
      className="rounded"
    />
    <div className="p-[8px]">
      <h3 className="judge-name mt-[24px] mb-[16px]">{judge.name}</h3>
      <p className="judge-title">{judge.title}</p>
    </div>
  </div>
)

export const Judges = () => (
  <Page
    header="Judges"
    title="Judging Committee"
    keywords="xrpl grants judging, xrpl expert judges, web3 judges, web3 experts, blockchain experts, ubri judges, xrpl judges"
    description="Meet the XRPL Grants panel of judges coming from all parts of the world and bringing key expertise within the blockchain or crypto space."
    slug="judges"
  >
    <section className="flex">
      <h2 className="flex-1">The Judges</h2>
      <div className="flex-1">
        <p className="longform">
          All applications for XRPL Grants are reviewed by members of the XRPL
          Grants Judging Committee.
        </p>
        <p className="mt-[48px]">
          XRPL Grants relies on a diverse panel of judges from around the world.
          Each is a respected member of their industry and among academia,
          bringing key expertise in the blockchain or crypto space.
        </p>
      </div>
    </section>
    <section>
      <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-8 lg:grid-cols-12 gap-y-[48px]">
        {
          // Sort by last name
          judgesData
            .sort((a, b) =>
              a.name.split(` `)[1].localeCompare(b.name.split(` `).reverse()[0])
            )
            .map((judge) => (
              <Judge judge={judge} key={judge.name} />
            ))
        }
        <p className="longForm col-span-2 sm:col-span-4 md:col-span-6 md:col-start-2 lg:col-span-8 lg:col-start-3 md:text-center prose">
          XRPL Grants seeks to continue to expand the panel of judges to
          increase the level and diversity of expertise in judging. If you are
          interested in becoming a member of the judging committee for XRPL
          Grants, please send an email outlining your experience to{` `}
          <UniversalLink to="mailto:info@xrplgrants.org">
            info@xrplgrants.org
          </UniversalLink>
        </p>
      </div>
    </section>
    <section>
      <h2 className="mb-[80px]">Affiliations</h2>
      <div className=" grid gap-x-[35px] gap-y-[45px] sm:gap-y-[75px] grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 items-center">
        {affiliates.map((affiliate) => (
          <div className="affiliation" key={affiliate.image}>
            <img
              src={`/images/affiliations/${affiliate.image}`}
              className="max-h-[50px] sm:max-h-[54px] md:max-h-[70px]"
              alt={affiliate.title}
            />
          </div>
        ))}
      </div>
    </section>
  </Page>
)

export default Judges
